import React from "react"

export default function BannerOne() {
  return (
    <div className="bannerOne_drones">
      <div>
        <h1>TRANSFORMACIÓN DIGITAL</h1>
        <p>Integra la tecnología digital en todas las áreas de tu empresa, cambiando fundamentalmente la operación para brindar valor a sus clientes.</p>
        </div>
    </div>
  )
  
}
