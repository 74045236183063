import React from "react"
import { PageWrapper } from "~components/Core"
import BannerOne from "~sections/TransformacionDigital/BannerOne"
import FooterOne from "~sections/marketing/FooterOne"
import ServicesSection from "~sections/TransformacionDigital/Services"

const header = {
  headerClasses:
    "site-header site-header--menu-start dark-header site-header--sticky",
  containerFluid: true,
  // customLogo: Images.HeaderLogo,
}

export default function TransformacionDigital() {
  return (
    <PageWrapper headerConfig={header}>
      <BannerOne />
      <ServicesSection />
      <FooterOne />
    </PageWrapper>
  )
}
