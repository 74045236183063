import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import ServicesCard from "./Component/Card"
import Service from "./style"
// import { Productos } from "~data"

const Digital = {
  services: [
    {
      id: "ss1",
      icon: "",
      title: "Consultoría",
      text:
        "Ofrecemos una consultoría para poder escuchar las necesidades y soluciones que podemos ofrecer.",
      iconBackground: "#000000",
      linkTo: "ciudades-inteligentes",
    },
    {
      id: "ss2",
      icon: "",
      title: "Mapeo y evaluación",
      text:
        "Marco estratégico, objetivos, ejes, calendario, alcance presupuestal, modelo de maduración y entregable. ",
      iconBackground: "#000000",
      linkTo: "drones-atv-rov-uav",
    },
    {
      id: "ss3",
      icon: "",
      title: "Proceso de construcción",
      text:
        "Se desglosa en cuatro etapas: programación, pre lanzamiento, configuraciones y lanzamiento.",
      iconBackground: "#000000",
      linkTo: "ciberseguridad",
    },
    {
      id: "ss4",
      icon: "",
      title: "Integración de tecnología y sistemas",
      text:
        "Tecnologías de punta y los mejores sistemas para desarrollo, para generar escalas en vertical y horizontal.",
      iconBackground: "#000000",
      linkTo: "desarrollo-de-software",
    },
    {
      id: "ss5",
      icon: "",
      title: "Lanzamiento",
      text:
        "Integraciones de la App / Android / los Beta, 1er lanzamiento con las dependencias, ejecución de la primer etapa.",
      iconBackground: "#000000",
      linkTo: "broker-de-seguros",
    },
    {
      id: "ss6",
      icon: "",
      title: "Promover",
      text:
        "Generamos campañas digitales, re dirigiendo a los clientes al call center, estableciendo KPI’s específicos.",
      iconBackground: "#000000",
      linkTo: "transformacion-digital",
    },
  ],
}

export default function ServicesSection({ ...rest }) {
  return (
    <Service backgroundColor="#fff" {...rest}>
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xxl-6 col-xl-7 col-md-9 col-sm-10">
            <Service.Box className="text-center" mb="30px" mbLG="55px">
              {/* <Service.Subtitle as="h6" fontColor="#ff1e38">
                Our Services
              </Service.Subtitle> */}
              <Service.Title as="h2">Productos</Service.Title>
            </Service.Box>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {Digital.services.map(({ title, icon, text, id, iconBackground }) => {
            return (
              <Col
                xs="12"
                className="col-xl-4 col-lg-6 col-md-6 col-sm-9 col-xs-10 "
                key={id}
              >
                <ServicesCard
                  title={title}
                  text={text}
                  icon={icon}
                  iconBackground={iconBackground}
                />
              </Col>
            )
          })}
        </Row>
      </Container>
    </Service>
  )
}
